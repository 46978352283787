// Others
import config from '@/config/env-constants';
import _ from 'lodash';

function getDefaultSessionObj() {
    return {
        sessionId: '',
        status: '',
        description: '',
        company: '',
        companyId: '',
        storageLocation: '',
        storageLocationId: '',
        connectedCompany: '',
        connectedCompanyId: '',
        connectedStorageLocation: '',
        connectedStorageLocationId: '',
        scanners: [],
        allowedScanners: [],
        inputAssetLog: config.inputAssetLogDefaultValue,
        scannedAssets: [],
        inTransitAssets: [],
        dateCreated: null,
        createdBy: '',
        dateUpdated: null,
        updatedBy: '',
        dateCancelled: null,
        cancelledBy: ''
    };
}

function getCurrStorageLocationId(session) {
    if (session.storageLocationId && session.storageLocationId.length > 0) {
        return session.storageLocationId;
    } else {
        return session.connectedStorageLocationId;
    }
}

function getCurrStorageLocationName(session) {
    if (session.storageLocationId && session.storageLocationId.length > 0) {
        return session.storageLocation;
    } else {
        return session.connectedStorageLocation;
    }
}

function getInventoriedBy(session, assetCode) {
    let inventoriedBy = "-";
    let scanners = session.scanners;
    _.forEach(scanners, scanner => {
        if (scanner.scannedAssets.includes(assetCode)) {
            inventoriedBy = scanner.id;
        }
    });
    return inventoriedBy;
}

function cleanupFields(session) {
    let cleanedSession = { ...session };

    delete cleanedSession['Storage Location'];
    delete cleanedSession['Date Created'];
    delete cleanedSession['Date Updated'];
    delete cleanedSession['Date Cancelled'];
    delete cleanedSession['Total'];
    delete cleanedSession['_showDetails'];

    return cleanedSession;
}

export const InventorySessionUtil = {
    getDefaultSessionObj,
    getCurrStorageLocationId,
    getCurrStorageLocationName,
    getInventoriedBy,
    cleanupFields
}